import React from 'react';
import { Col, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { RiDownloadLine } from 'react-icons/ri';

import api, { base, lang } from '../../services/api';

export default class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      getting: false,
      page: 0,
      images: [],
      prevY: 0,
      hasMore: true,
      menus: [],
      slices: []
    };
  }

  componentDidMount() {
    this.getImages();
  }

  getImages = () => {
    const perPage = 11;
    
    if(this.state.getting) return;
    this.setState({ getting: true });

    let start = (perPage * this.state.page) + this.state.page;
    let end = start + perPage;

    let params = {
      slice: this.props.match.params.slice,
      start,
      end,
      fullurl: 1
    };
    
    let images = [...this.state.images];

    api.get(`getposts${lang === 'br' ? '' : '_en'}.php`, { params }).then(res => {
      let hasMore = res.data.posts.length < perPage ? false : true;
    console.log(res.data.posts);
      res.data.posts.map(i => {
        i.pics.map(p => {
          images.push(p);
        });
      });

      this.setState({
        loading: false,
        getting: false,
        images,
        menus: res.data.menus,
        slices: res.data.slices,
        hasMore,
        page: this.state.page + 1 
      }, () => console.log(this.state));
    });
  }

  render() {
    return (
      <>
        {!this.state.loading &&
          <>
            <Header menus={this.state.menus} slices={this.state.slices} />
            <div className="container">
              <h1 className="title">{this.props.match.params.slice}</h1>
              <InfiniteScroll
                dataLength={this.state.images.length}
                next={this.getImages}
                hasMore={this.state.hasMore}
                // loader={<p><b>{lang === 'br' ? 'Carregando...' : 'Loading...'}</b></p>}
                refreshFunction={this.getImages}
                pullDownToRefresh
                pullDownToRefreshThreshold={50}
              >
                <Row>
                  {this.state.images.map((i,index) => (
                    <Col key={index} sm={4} xs={12}>
                      <div key={index} className="sliderGallery">
                        <div className="imgGallery">
                          <img src={`${i.pic_v}`} alt={i.caption} />
                          {/* <div className="typePic">
                            <MdPhotoCamera /> FOTO
                          </div> */}
                        </div>
                        <div className="contentGallery">
                          <b>LEGENDA:</b>
                          <p>{i.caption}</p>
                          <div className="boxFlex">
                            <div className="item">
                              <b>CRÉDITOS:</b>
                              <p>{i.credits}</p>
                            </div>
                            <div className="item">
                              <a href={i.fullpic_v} target="_blank" download>
                                <RiDownloadLine />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </InfiniteScroll>
            </div>
            <Footer />
          </>
        }
      </>
    );
  }
}