import React from 'react';

import { Col, Row } from 'react-bootstrap';

import './styles.css';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import MainBanner from '../../components/MainBanner';
import MostClicked from '../../components/MostClicked';
import Region from '../../components/Region';
import Brand from '../../components/Brand';
import Galeria from '../../components/Galeria';
import Institucional from '../../components/Institucional';
import Revenues from '../../components/Revenues';
import Careers from '../../components/Careers';
import ESG from '../../components/ESG';

import api, { lang } from '../../services/api';

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      menus: [],
      slices: [],
      banner: [],
      institutional: [],
      esg: [],
      gallery: [],
      region: [],
      brand: [],
      loading: true,
    };
  }

  componentDidMount() {
    api.get(`/getposts${lang === 'br' ? '' : '_en'}.php?fullurl`).then(res => {
      this.setState({
        menus: res.data.menus,
        slices: res.data.slices,
        banner: res.data.posts.filter(p => { return p.slice === res.data.slices[0].name && p.pics.length > 0; }),
        institutional: res.data.posts.filter(p => { return p.slice === res.data.slices[1].name; }),
        esg: res.data.posts.filter(p => { return p.slice === res.data.slices[2].name; }),
        gallery: res.data.posts.filter(p => { return p.slice === res.data.slices[3].name; }),
        region: res.data.posts.filter(p => { return p.slice === res.data.slices[4].name; }),
        brand: res.data.posts.filter(p => { return p.slice === res.data.slices[5].name; }),
        loading: false
      });
    });
  }

  render() {
    return (
      <>
        {!this.state.loading &&
          <>
            <Header menus={this.state.menus} slices={this.state.slices} />
            <div className="container">
              {this.state.banner.length > 0 &&
                <MainBanner news={this.state.banner} />
              }
              {this.state.institutional.length > 0 &&
                <Institucional news={this.state.institutional} slices={this.state.slices} />
              }
              {this.state.esg.length > 0 &&
                <ESG news={this.state.esg} slices={this.state.slices} />
              }
              {this.state.region.length > 0 &&
                <Region news={this.state.region} slices={this.state.slices} />
              }
              {this.state.brand.length > 0 &&
                <Brand news={this.state.brand} slices={this.state.slices} />
              }
              {this.state.gallery.length > 0 &&
                <Galeria images={this.state.gallery} slices={this.state.slices} />
              }
            </div>
            <Footer />
          </>
        }
      </>
    );
  }
}  