import React from 'react';

import { Col, Row } from 'react-bootstrap';

import News from '../News';

import './styles.css';

export default class Careers extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      //
    };
  }

  componentDidMount() {
    //
  }

  render() {
    return (
      <div id="careers">
        <Row>
            <Col>
                <ul className="titleCategories">
                    <li>Carreiras</li>
                </ul>
            </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <News />
            <News />
          </Col>
          <Col sm={4}>
            <News />
            <News />
          </Col>
          <Col sm={4}>
            <News />
            <News />
          </Col>
        </Row>
      </div>
    );
  }
}  