import React from 'react';

import './styles.css';

import { base } from '../../services/api';

export default class MainBanner extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    //
  }
  
  render() {
    return (
      <div id="mainBanner">
        
        <div id="mbLeft" onClick={() => window.location.href=this.props.news[0] ? `/noticia/${this.props.news[0].slug}` : ''}>
          <img src={this.props.news[0] ? `${this.props.news[0].pics[0].pic_h}` : ''} alt={this.props.news[0] ? this.props.news[0].title : ''} />
          <div className="mbOverlay"></div>
          <div className="infoBox white">
            <ul>
              {/* <li>{this.props.news[0] ? moment(this.props.news[0].date).format('DD/MM/YYYY') : ''}</li> */}
              {/* <li><a href={`/card/${this.props.news[0] ? this.props.news[0].extra.card : ''}`}>{this.props.news[0] ? this.props.news[0].extra.card : ''}</a></li> */}
              {/* <li><a href="/">Produto</a></li> */}
            </ul>
          </div>
          <h2>{this.props.news[0] ? this.props.news[0].title : ''}</h2>
        </div>

        <div id="mbRight">
          <div id="mbrTop"  onClick={() => window.location.href=this.props.news[1] ? `/noticia/${this.props.news[1].slug}` : ''}>
            <img src={this.props.news[1] ? `${this.props.news[1].pics[0].pic_h}` : ''} alt={this.props.news[1] ? this.props.news[1].title : ''} />
            <div className="mbOverlay"></div>
            <div className="infoBox white">
              <ul>
                {/* <li>{this.props.news[1] ? moment(this.props.news[1].date).format('DD/MM/YYYY') : ''}</li> */}
                {/* <li><a href={`/card/${this.props.news[1] ? this.props.news[1].extra.card : ''}`}>{this.props.news[1] ? this.props.news[1].extra.card : ''}</a></li> */}
                {/* <li><a href="/">Produto</a></li> */}
              </ul>
            </div>
            <h2>{this.props.news[1] ? this.props.news[1].title : ''}</h2>
          </div>
          <div id="mbrBottom">
            <div id="mbrBottomLeft" onClick={() => window.location.href=this.props.news[2] ? `/noticia/${this.props.news[2].slug}` : ''}>
            <img src={this.props.news[2] ? `${this.props.news[2].pics[0].pic_h}` : ''} alt={this.props.news[2] ? this.props.news[2].title : ''} />
              <div className="mbOverlay"></div>
              <div className="infoBox small white">
                <ul>
                  {/* <li>{this.props.news[2] ? moment(this.props.news[2].date).format('DD/MM/YYYY') : ''}</li> */}
                </ul>
              </div>
              <h2>{this.props.news[2] ? this.props.news[2].title : ''}</h2>
            </div>
            <div id="mbrBottomRight"  onClick={() => window.location.href=this.props.news[3] ? `/noticia/${this.props.news[3].slug}` : ''}>
            <img src={this.props.news[3] ? `${this.props.news[3].pics[0].pic_h}` : ''} alt={this.props.news[3] ? this.props.news[3].title : ''} />
              <div className="mbOverlay"></div>
              <div className="infoBox small white">
                <ul>
                  {/* <li>{this.props.news[3] ? moment(this.props.news[3].date).format('DD/MM/YYYY') : ''}</li> */}
                </ul>
              </div>
              <h2>{this.props.news[3] ? this.props.news[3].title : ''}</h2>
            </div>
          </div>
        </div>
      </div>
    );
  }
}  