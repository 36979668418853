import axios from 'axios';

export const base = 'https://mediaroom.jbs.com.br';
export const ag = 14072;
export const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'br';

const api = axios.create ({ 
    baseURL:  `${base}`
});


export default api;