import React from 'react';
import { Col, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import News from '../../components/News';

import './styles.css';

import api, { base, lang } from '../../services/api';

export default class Result extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      getting: false,
      page: 0,
      news: [],
      prevY: 0,
      hasMore: true,
      menus: [],
      slices: []
    };
  }

  componentDidMount() {
    this.getNews();
  }

  getNews = () => {
    const perPage = 11;
    
    if(this.state.getting) return;
    this.setState({ getting: true });
    // let form = new FormData();

    let start = (perPage * this.state.page) + this.state.page;
    let end = start + perPage;

    let params = {
      start,
      end,
      fullurl: 1,
      nogallery: 1,
      orderbydate: 1
    };

    console.log(`start: ${params.start}`, `end: ${params.end}`);

    if(this.props.match.params.param){
      params[this.props.match.params.param] = this.props.match.params.value;
    }

    api.get(`getposts${lang === 'br' ? '' : '_en'}.php`, { params }).then(res => {
      let hasMore = res.data.posts.length < perPage ? false : true;
      this.setState({
        loading: false,
        getting: false,
        news: [...this.state.news, ...res.data.posts],
        menus: res.data.menus,
        slices: res.data.slices,
        hasMore,
        page: this.state.page + 1 
      }, () => console.log(this.state));
    });
  }

  render() {
    return (
      <>
        {!this.state.loading &&
          <>
            <Header menus={this.state.menus} slices={this.state.slices} />
            <div className="container">
              <h1 className="title">{this.props.match.params.card}</h1>
              <InfiniteScroll
                dataLength={this.state.news.length}
                next={this.getNews}
                hasMore={this.state.hasMore}
                // loader={<p><b>{lang === 'br' ? 'Carregando...' : 'Loading...'}</b></p>}
                refreshFunction={this.getNews}
                pullDownToRefresh
                pullDownToRefreshThreshold={50}
              >
                <Row>
                  {this.state.news.map((n,index) => (
                    <Col key={index} sm={6} xs={12}>
                      <News 
                        date={n.shortdate} 
                        title={n.title} 
                        desc={n.description} 
                        tags={n.tags}
                        slug={n.slug} 
                      />              
                    </Col>
                  ))}
                </Row>
              </InfiniteScroll>
              {!this.props.match.params.param &&
                <div className="oldNews">
                  <a href="https://jbs.com.br/comunicacao/ultimas-noticias/">{lang === 'br' ? 'Arquivo de notícias' : 'News archive'}</a>
                </div>
              }
            </div>
            <Footer />
          </>
        }
      </>
    );
  }
}