import React from 'react';

import { Col, Row } from 'react-bootstrap';

import Select from 'react-select';

import News from '../News';

import './styles.css';

import { base, lang } from '../../services/api';

export default class Brand extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      leftNews: [],
      bannerNews: [],
      rightNews: [],
      brand: '',
      label: lang === 'br' ? 'Selecione...' : 'Choose...'
    };
  }

  componentDidMount() {
    this.setNews();
    this.setOptions();
  }

  setOptions = () => {
    let options = [{ value: '', label: lang === 'br' ? 'Selecione...' : 'Choose...' }];

    if(this.props.slices[5]){
      if(this.props.slices[5].cards.length > 0){
        this.props.slices[5].cards.map(card => {
          options.push({ value: card, label: card });
        });
      }
    }

    this.setState({ options });
  }

  setNews = () => {
    let leftNews = [];
    let bannerNews = [];
    let rightNews = [];
    let news = this.props.news;

    if(this.state.brand !== ''){
      news = news.filter(n => n.cards.includes(this.state.brand));
    }

    if(news.length > 0){
      news.map(n => {
        if(bannerNews.length < 1){
          bannerNews.push(n);
        } else if (leftNews.length < 3) {
          leftNews.push(n);
        } else if(rightNews.length < 3){
          rightNews.push(n);
        }
      });
    }

    this.setState({ leftNews, bannerNews, rightNews });
  }

  handleBrandChange = (option) => {
    this.setState({ brand: option.value, label: option.label }, () => this.setNews() );
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false, //true
      speed: 1000,
      autoplaySpeed: 5000,
      arrows: false
    };
    
    return (
      <div id="brand">
        <Row>
            <Col sm={12}>
                <ul className="titleCategories">
                  <li>{this.props.slices[5]?.name}</li>
                </ul>
                <Select options={this.state.options} value={{value: this.state.brand, label: this.state.label}} onChange={(option) => this.handleBrandChange(option)} />
                <a className="show-all" href={`/slice/${this.props.slices[5]?.name}`}>{lang === 'br' ? 'Ver mais' : 'View All'}</a>
            </Col>
        </Row>
        <Row>
          {this.state.bannerNews.length > 0 &&
            <Col sm={5} lg={4}>
              <div id="mainBannerBrand" onClick={() => window.location.href=`/noticia/${this.state.bannerNews[0].slug}`}>
                <div id="mbLeft" style={{width: '100%'}}>
                  <img 
                    src={this.state.bannerNews[0].pics.length > 0 ? `${this.state.bannerNews[0].pics[0].pic_v}` : ''} 
                    alt={this.state.bannerNews[0].title} 
                  />
                  <div className="mbOverlay"></div>
                  <h2>{this.state.bannerNews[0].title}</h2>
                </div>
              </div>
            </Col>
          }

          {this.state.leftNews.length > 0 &&
            <Col sm={7} lg={4}>
              {this.state.leftNews.map((l,i) => (
                <News 
                  key={i}
                  pics={l.pics}
                  title={l.title} 
                  desc={l.description}
                  cards={l.cards}
                  slug={l.slug}
                />
              ))}
            </Col>
          }

          {this.state.rightNews.length > 0 &&
            <Col sm={12} lg={4}>
              {this.state.rightNews.map((r,i) => (
                <News 
                  key={i}
                  pics={r.pics}
                  title={r.title} 
                  desc={r.description}
                  cards={r.cards}
                  slug={r.slug}
                />
              ))}
            </Col>
          }
        </Row>
      </div>
    );
  }
}  