import React from 'react';

import { Col, Row } from 'react-bootstrap';

import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { RiDownloadLine } from 'react-icons/ri';
import { MdPhotoCamera } from 'react-icons/md';
import { BsCameraVideoFill } from 'react-icons/bs';

import galeria from '../../assets/images/galeria.jpg';

import { base, lang } from '../../services/api';

import './styles.css';

export default class Galeria extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      images: [],
    };
  }

  componentDidMount() {
    this.setImages();
  }

  setImages = () => {
    let limit = this.props.slices[2].maxposts;
    let images = [];

    this.props.images.map(i => {
      i.pics.map(p => {
        if(images.length < limit) images.push(p);
      });
    });

    this.setState({ images });
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 2000,
      arrows: true,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    };
    return (
      <>
        <Row>
          <Col>
              <ul className="titleCategories">
                <li>{this.props.slices[3]?.name}</li>
                <li><a className="show-all" href={`/images/${this.props.slices[3]?.name}`}>{lang === 'br' ? 'Ver mais' : 'View All'}</a></li>
              </ul>
          </Col>
        </Row>
        <div>
          <Slider {...settings}>
            {this.state.images.map((i, index) => (
              <div key={index} className="sliderGallery">
                <div className="imgGallery">
                  <img src={`${i.pic_v}`} alt={i.credits} />
                  {/* <div className="typePic">
                    <MdPhotoCamera /> FOTO
                  </div> */}
                </div>
                <div className="contentGallery">
                  <b>LEGENDA:</b>
                  <p>{i.caption}</p>
                  <div className="boxFlex">
                    <div className="item">
                      <b>CRÉDITOS:</b>
                      <p>{i.credits}</p>
                    </div>
                    <div className="item">
                      <a href={`${i.fullpic_v}`} target="_blank" download>
                        <RiDownloadLine />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </>
    );
  }
}  