import React from 'react';

import { Col, Row } from 'react-bootstrap';

import News from '../News';

import { lang, base } from '../../services/api';


import './styles.css';

export default class Institucional extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    //
  }

  render() {
    return (
      <div id="institucional">
        <Row>
            <Col sm={12} lg={4}>
                <ul className="titleCategories">
                    <li>{this.props.slices[1]?.name}</li>
                    <li><a className="show-all" href={`/slice/${this.props.slices[1]?.name}`}>{lang === 'br' ? 'Ver mais' : 'View All'}</a></li>
                </ul>
            </Col>
        </Row>
        
        <Row>
          <Col sm={12} lg={4}>
            {this.props.news[0] &&
              <div id="mainBannerInstitucional" onClick={() => window.location.href=`/noticia/${this.props.news[0].slug}`}>
                <div id="mbLeft" style={{width: '100%'}}>
                  {this.props.news[0].pics.length > 0 &&
                    <img src={this.props.news[0] ? `${this.props.news[0].pics[0].pic_v}` : ''} alt={this.props.news[0] ? this.props.news[0].title : ''} />
                  }
                  <div className="mbOverlay"></div>
                  <h2>{this.props.news[0].title}</h2>
                </div>
              </div>
            }
          </Col>
          <Col sm={12} lg={4}>
            {this.props.news[1] &&
              <div id="mainBannerInstitucional" className="lastBannerInstitucional" onClick={() => window.location.href=`/noticia/${this.props.news[1].slug}`}>
                <div id="mbLeft" style={{width: '100%'}}>
                  {this.props.news[1].pics.length > 0 &&
                    <img src={this.props.news[1] ? `${this.props.news[1].pics[0].pic_v}` : ''} alt={this.props.news[1] ? this.props.news[1].title : ''} />
                  }
                  <div className="mbOverlay"></div>
                  <h2>{this.props.news[1].title}</h2>
                </div>
              </div>
            }
          </Col>
          <Col sm={12} lg={4}>
            {this.props.news.length > 0 &&
              <div id="newsAboutUs">
                {this.props.news.map((n,index) => {
                  if(index >= 2 && index < 6){
                    return (
                      <News key={index}
                        title={n.title}
                        desc={n.description}
                        edt0={n.tags.length > 0 ? n.tags[0] : ''}
                        slug={n.slug}
                        date={n.pubDate}
                      />
                    );
                  }
                })}
              </div>
            }
          </Col>
        </Row>
      </div>
    );
  }
}  