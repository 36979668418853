import React from 'react';
import moment from 'moment';

import { Col, Row } from 'react-bootstrap';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import News from '../../components/News';

import './styles.css';

import api, { base, lang } from '../../services/api';

export default class SingleNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      menus: [],
      slices: [],
      news: {},
      related: []
    };
  }

  componentDidMount() {
    this.getNews();
  }

  getNews = () => {
    // let form = new FormData();

    // form.append('ag', ag);
    // form.append('lang', lang);
    // form.append('search', this.props.match.params.slug);
    
    // api.post('/getPressRoomPost.php', form).then(res => {
    //   this.setState({ news: res.data }, () => this.getRelated());
    // });

    api.get(`getposts${lang === 'br' ? '' : '_en'}.php?slug=${this.props.match.params.slug}&fullurl`).then(res => {
      this.setState({
        news: res.data.posts.length > 0 ? res.data.posts[0] : {},
        menus: res.data.menus,
        slices: res.data.slices,
        slice: '',
        loading: false
      }, () => this.getRelated());
    });
  }

  getRelated = () => {
    let slice = Object.keys(this.state.news).length > 0 ? this.state.news.slice : null;
    let firsfold = Object.keys(this.state.news).length > 0 ? this.state.news.firstfold : null;
    let params = '';

    if(firsfold === 1){
      params = `firstfold=1`;
    } else {
      params = `slice=${slice}`;
    }

    if(slice){
      api.get(`getposts${lang === 'br' ? '' : '_en'}.php?${params}&fullurl&nogallery`).then(res => {
        let toRelated = res.data.posts.filter(p => p.slug !== this.state.news.slug);
        toRelated = toRelated.slice(0,6);

        this.setState({
          related: toRelated,
          slice
        });
      });
    }
  }

  render() {
    return (
      <>
        {!this.state.loading &&
          <>
            <Header menus={this.state.menus} slices={this.state.slices} />
            <div className="container">
              <div className="content">
                <div className="boxTopNotice">
                  {this.state.news.shortdate &&
                    <div className="dateNotice">
                      <span>{this.state.news.shortdate}</span>
                    </div>
                  }
                </div>
                <h1 className="newsSingleTitle">{this.state.news.title}</h1>
                <h2 className="newsSingleSubtitle">{this.state.news.description}</h2>
                
                <div className="newsSingleContent" dangerouslySetInnerHTML={{__html: this.state.news.contents}} />
                
                {this.state.news.tags &&
                  <>
                    {this.state.news.tags.length > 0 &&
                      <div className="singleNewsTags">
                        {this.state.news.tags.map(tag => (
                          <a href={`/tag/${tag}`}><span>{tag}</span></a>
                        ))}
                      </div>
                    }
                  </>
                }                
                
                {this.state.related.length > 0 &&
                  <h3 className="titleMoreNews">{lang === 'br' ? `Mais notícias ${this.state.slice === 'Primeira Dobra' ? 'Em destaque' : `de ${this.state.slice}`}` : 'More news'}</h3>
                }
                

                <Row>
                  {this.state.related.map((r,index) => (
                    <Col key={index} sm={6} xs={12}>
                      <News
                        title={r.title} 
                        desc={r.description} 
                        tags={r.tags}
                        slug={r.slug}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
            <Footer />
          </>
        }
      </>
    );
  }
}  