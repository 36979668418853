import React from 'react';

import { Col, Row } from 'react-bootstrap';

import steak from '../../assets/images/steak.jpg';

import './styles.css';

export default class Revenues extends React.Component {
  render() {
    return (
      <div id="revenues">
        <Row>
            <Col>
                <ul className="titleCategories">
                    <li>Receitas</li>
                </ul>
            </Col>
        </Row>
        <Row>
            <Col>
                <div className="boxRevenue">
                  <Row>
                    <Col sm={5}>
                      <img src={steak} alt="Receita" />
                    </Col>
                    <Col sm={7}>
                      <div className="aboutRevenue">
                        <h2>Galloway Beef Fillet Steaks</h2>
                        <h3>Ingredientes</h3>
                        <ul>
                          <li>3 tomates picadinhos</li>
                          <li>1 cebola picada</li>
                          <li>1 dente de alho</li>
                          <li>1/2 xícara de chá de azeitonas picadas</li>
                          <li>1/2 colher de chá de noz moscada</li>
                          <li>Salsinha e cebolinha à gosto</li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
            </Col>
        </Row>
        <Row>
          <Col>
            <div id="btns">
              <button className="btnsRevenue" id="btnPrev">Anterior</button>
              <button className="btnsRevenue" id="btnNext">Próxima receita</button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}  