import React from 'react';

import News from '../News';

import './styles.css';

import api, { ag, lang } from '../../services/api';

export default class MostClicked extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      news: []
    };
  }

  componentDidMount() {
    // let form = new FormData();

    // form.append('ag', ag);
    // form.append('lang', lang);
    // form.append('mostclicked', 1);
    // form.append('start', 0);
    // form.append('end', 2);

    // api.post('/getPressRoomPostRange.php', form).then(res => this.setState({ news: res.data }));
  }

  render() {
    return (
      <div id="mostClicked">
        <h4>{lang === 'br' ? 'Mais clicadas' : 'Most popular'}</h4>
        <News 
          order={1} 
          title={this.state.news[0] ? this.state.news[0].title : null}
          desc={this.state.news[0] ? this.state.news[0].extra.desc : null}
          card={this.state.news[0] ? this.state.news[0].extra.card : null}
          slug={this.state.news[0] ? this.state.news[0].slug : null}
          date={this.state.news[0] ? this.state.news[0].date : null}
        />
        <News 
          order={2}
          title={this.state.news[1] ? this.state.news[1].title : null} 
          desc={this.state.news[1] ? this.state.news[1].extra.desc : null} 
          card={this.state.news[1] ? this.state.news[1].extra.card : null} 
          slug={this.state.news[1] ? this.state.news[1].slug : null} 
          date={this.state.news[1] ? this.state.news[1].date : null}
        />
        <News 
          order={3} 
          title={this.state.news[2] ? this.state.news[2].title : null}
          desc={this.state.news[2] ? this.state.news[2].extra.desc : null}
          card={this.state.news[2] ? this.state.news[2].extra.card : null}
          slug={this.state.news[2] ? this.state.news[2].slug : null}
          date={this.state.news[2] ? this.state.news[2].date : null}
        />
      </div>
    );
  }
}  